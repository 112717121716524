import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

// export default new Vuetify({
//   theme: {
//     dark: true
//   }
// })

const vuetify = new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: '#262a37',
        secondary: '#3a3e50',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    },
    dark: true
  }
})

export default vuetify
